footer
{    
    /*flex-direction: row;*/
    margin-top: 50px;
    display: flex;
    min-height: 180px;
    background: #3c4052;
    padding: 5px;
    justify-content: center;
    align-items: center;
    flex-wrap:nowrap;
    flex-direction: row;
    /*flex-wrap: wrap;*/
    
}
footer .box
{
  flex-wrap:nowrap;
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-self :center;
  justify-content: center;
}


footer p
{
  color: white;
  font-size: 25px;
  margin: 5px;
  text-align: center;  

}
/*@media only screen and (max-width: 600px) {
  img {
    max-width: 60px;
  }
}*/
