* {

  color: rgb(0, 0, 0);
}

p {
  text-indent: 0px;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /*color: white;*/
  /*background: linear-gradient(20deg, #000000b4 0%, #686868 95%), #3f3131;*/

}

.content {
  display: flex;
  flex-grow: 1;
  margin-right: auto;
  margin-left: auto;
  width: 70%;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  
  /*padding: 50px;
  /*background: blue;*/
}

.content div {
  margin: 5px;
  font-size: 24px;
  font-weight:600;
}

.content button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  vertical-align: middle;
  color: rgb(255 255 255);
  text-align: center;
  border-radius: 5px;
  background-color: #17A085;
  line-height: 22px;
  height: 40px;
  padding: 10px 20px;
  box-sizing: border-box;
  border: none;
  margin: 0;
  transition: background-color 0.2s;
  min-width: 150px;
  font-weight:600;
  
}

.content .contentBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

.content .info {
  max-width: 600px;
}

.info h2 {
  font-size: 35px;
  text-align: center;
}

li {
  font-size: 20px;
  margin: 10px;
}

.linc {
  font-size: 25px;
  text-align: center;
}

.linc:hover {
  cursor: pointer;
}

/*p {
  font-size: 25px;
  text-indent: 5%;
  text-align: justify;
}*/

.imgPages {
  margin: 0px;
  /*width: 450px;*/
  max-height: 400px;
  border-radius: 12px;
}

.imgMe {
  margin: 0px;
  /*width: 450px;*/
  max-height: 666px;
  border-radius: 12px;

}

.input-file {
  position: relative;
  display: inline-block;
}

.input-file-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
  line-height: 40px;
  text-align: left;
  height: 40px;
  display: block;
  float: right;
  box-sizing: border-box;
  width: 600px;
  /*border-radius: 6px 0px 0 6px;*/
  border: 1px solid #ddd;
}

.input-file-btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  vertical-align: middle;
  color: rgb(255 255 255);
  text-align: center;
  min-width: 150px;
  /*border-radius: 4px 0px 0px 4px;*/
  background-color: #17A085;
  line-height: 22px;
  height: 40px;
  padding: 10px 20px;
  box-sizing: border-box;
  border: none;
  margin: 0;
  transition: background-color 0.2s;
  
}

.br-left {
  border-radius: 4px 0px 0px 4px;
  font-weight:600;

}

.br-right {
  border-radius: 0px 4px 4px 0px;

}

.input-file input[type=file] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

/* Focus */
.input-file input[type=file]:focus+.input-file-btn {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

/* Hover/active */
.content button:hover,
.input-file-btn:hover {
  background-color: #1bc09f;
}

/*.input-file:active .input-file-btn {
	background-color: #2E703A;
}*/

/* Disabled */
.input-file input[type=file]:disabled+.input-file-btn {
  background-color: #eee;
}




@media only screen and (max-width: 1600px) {
  .content {
    width: 90%;
    padding: 0px;
  }

  .content .info {
    max-width: 400px;
  }

}

@media only screen and (max-width: 700px) {
  .imgMe {
    max-width: 95%;

  }

  .imgPages {
    max-width: 90%;
  }

  p {
    font-size: 0.7rem;
  }

  li {
    font-size: 1.2rem;
  }

}