.navbar {
    display: flex;
    /*flex-direction: row;*/
    justify-content: center;
    align-items:center;
    
    min-height: 50px;
    background: #3c4052;
    box-shadow: 0px 5px 15px rgba(245, 242, 242, 0.363);
    
}
.navbar .box{
    display: flex;
    width: 70%;
    justify-content: left;    

}

.navbar a {
    float: left;
    font-size: 16px;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-weight:600;
    transition: background-color 0.3s;
  }
  
  /* Выпадающий контейнер */
  .dropdown {
    float: left;
    overflow: hidden;
  }
  
  /* Кнопка выпадающего списка */
  .dropdown .dropbtn {
    font-size: 20px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit; /* Важно для вертикального выравнивания на мобильных телефонах */
    margin: 0; /* Важно для вертикального выравнивания на мобильных телефонах */
    font-weight:700;
    width: 250px;
    transition: background-color 0.3s;
  }
  
  /* Добавить красный цвет фона для ссылок на навигационную панель при наведении курсора */
  .navbar a:hover, .dropdown:hover .dropbtn {
    background-color:#17A085;
  }
  
  /* Выпадающее содержимое (скрыто по умолчанию) */
  .dropdown-content {
    width: 250px;
    display: none;
    position: absolute;
    background: #3c4052;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    
  }
  
  /* Ссылки внутри выпадающего списка */
  .dropdown-content a {
    float: none;
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
   /* margin: 5px;*/

  }
  
  /* Добавить серый цвет фона для выпадающих ссылок при наведении курсора */
  .dropdown-content a:hover {
    background-color: #17A085;
  }
  
  /* Показать выпадающее меню при наведении курсора */
  .dropdown:hover .dropdown-content {
    display: block;
  }